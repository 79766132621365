import React from "react";
import styled from "styled-components";
import {Switch} from "../atoms/switch";


interface ToggleSwitchProps {
    title: string
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = (props) => {
    return (
        <StyledToggleSwitch>
            <div style={{display: "flex"}}>
                <Switch/>
                <StyledToggleLabel>{props.title}</StyledToggleLabel>
            </div>
            <a href={"resume.pdf"} download={true}>PDF Version</a>
        </StyledToggleSwitch>
    )
}

const StyledToggleSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledToggleLabel = styled.label`
  margin-left: 12px;
`